import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, forkJoin, map, of, switchMap, tap } from "rxjs";
import { IGoalChildReorderPayload, IGoalChildReorderResponse, IWorkflowActions } from "@gtmhub/goals/models";
import { OKRsEventType } from "@gtmhub/okrs/events";
import { ColoringService } from "@webapp/configuration/services/coloring.service";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { GoalsRequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection, ICollectionMap } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IBulkDeleteResponse } from "@webapp/okrs/models/okrs-response.models";
import { Tag } from "@webapp/tags/models/tag.model";
import {
  CreateGoalDTO,
  GetGoalParams,
  Goal,
  GoalDTO,
  GoalInTree,
  IAsigneeIdsGoalsMap,
  IBulkCreateGoalRequest,
  IBulkCreateGoalResult,
  UpdateGoalAdditionalPayloadData,
} from "../models/goal.models";
import { GoalsApiService } from "./goals-api.service";
import { GoalsState } from "./goals-state.service";

const getGoalsFieldsV2 =
  "metrics{id,name,attainment,ownerIds,manualType,goalId,customFields,actual,format,links{spec},cascadeType,sourceMetricId,targetMetricIds,orderId,confidence}," +
  "parentId,parentType,ownerIds,description," +
  "name,dateFrom,dateTo,childrenCount,fullSubTreeCount,metricsCount,attainment,attainmentTypeString,fullAggregatedAttainment,sessionId," +
  "tags{id,name,title,isActive,itemsTaggedCount},customFields,obfuscated,private,parentGoalSessionId,locked,workflow{status},orderId,designScore{totalPoints}";

const fields =
  "metrics{trend,confidence,customFields,description,ownerIds,name,attainment,insightName,fieldName,settings,lastCheckInDate,targetOperator,dynamic," +
  "format,target,critical,initialValue,actual,goalId,manualType,softDueDate,dueDate,progressStatus,links{spec},cascadeType,sourceMetricId,targetMetricIds,orderId,sessionId}," +
  "currentUserAllowedActions,parentId,parentType,ownerIds,assignee{name,email,color,avatar,picture,type},description," +
  "name,dateFrom,dateTo,childrenCount,closedStatus,fullSubTreeCount,metricsCount,designScore,attainment,attainmentTypeString,fullAggregatedAttainment,sessionId," +
  "tags{id,name,title,isActive,itemsTaggedCount},customFields,obfuscated,private,access,parentGoalSessionId," +
  "workflow{status,reviewersNeeded,reviewers,approvedAt},locked,orderId,assignees,watchers";

export const fieldsLinks = fields + ",links{spec,expanded}";

export interface IResponseProcessing {
  applyColoring?: boolean;
}

@Injectable({
  providedIn: "any",
})
export class GoalsFacade extends BaseFacade<Goal, GoalDTO, GoalsState, GoalsApiService> {
  constructor(
    state: GoalsState,
    api: GoalsApiService,
    private broadcastService: BroadcastService,
    private coloringService: ColoringService
  ) {
    super(state, api);
  }

  private defaultResponseProcessing: IResponseProcessing = {
    applyColoring: true,
  };

  public deleteGoals$(goalsIds: string[]): Observable<IBulkDeleteResponse> {
    return this.deleteMany$(goalsIds, {
      ...new RequestConfig(),
      url: this.apiService.getBulkDeleteGoalsEndpoint(),
    });
  }

  public getGoalsV2$<T = Goal>(
    filter?: GoalsRequestPaging,
    additionalGtmhubParams?: GtmhubAdditionalParams,
    responseProcessing: IResponseProcessing = this.defaultResponseProcessing
  ): Observable<ICollection<T>> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService.getAll$<ICollection<T>>(filter, { ...new RequestConfig(), ...context }).pipe(
      map((collection) => {
        if (responseProcessing.applyColoring) {
          return this.applyColoringToGoalCollection(collection);
        }
        return collection;
      })
    );
  }

  public createGoal$(
    goal: CreateGoalDTO,
    options: { skipNotification?: boolean; cloned?: boolean } = {},
    additionalGtmhubParams?: GtmhubAdditionalParams
  ): Observable<Goal> {
    const config: RequestConfig = {};

    if (options.skipNotification) {
      config.queryParams = { skipNotification: options.skipNotification };
    }
    if (options.cloned) {
      config.queryParams = { cloned: options.cloned };
    }

    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.post$(goal, {
      ...new RequestConfig(),
      ...context,
      queryParams: {
        ...config.queryParams,
      },
    }).pipe(
      tap((newGoal) => {
        this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalCreated", detail: { goal: newGoal } });
      })
    );
  }

  public deleteGoal$(goalId: string, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Goal> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.delete$(goalId, {
      ...new RequestConfig(),
      ...context,
    }).pipe(
      tap(() => {
        this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalDeleted", detail: { goalId } });
      })
    );
  }

  public getGoal$(goalId: string, params?: GetGoalParams, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Goal> {
    const context = { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) };
    return this.get$<Goal>(goalId, {
      ...new RequestConfig(),
      ...context,
      queryParams: { ...params },
    }).pipe(switchMap((goal) => this.applyColoringToGoal(goal)));
  }

  private applyColoringToGoal(goal: GoalInTree): Observable<Goal> {
    goal.color = this.coloringService.getColor(goal.attainment);
    goal.aggregatedAttainmentColor = this.coloringService.getColor(goal.fullAggregatedAttainment);

    if (goal.metrics) {
      for (const metric of goal.metrics) {
        this.applyColoringToMetric(metric);
      }
    }

    return of(goal);
  }

  public applyColoringToGoalCollection<T>(collection: ICollection<T>): ICollection<T> {
    for (const goal of collection.items) {
      this.applyColoringToGoal(goal as Goal);
    }

    return collection;
  }

  private applyColoringToMetric(metric: Metric): Metric {
    metric.color = this.coloringService.getColor(metric.attainment);
    return metric;
  }

  public applyColoringToMetricCollection(collection: ICollection<Metric>): ICollection<Metric> {
    for (const goal of collection.items) {
      this.applyColoringToMetric(goal);
    }

    return collection;
  }

  public patchGoal$(goalId: string, goal: Partial<Goal>, gtmhubAdditionalParams?: GtmhubAdditionalParams): Observable<Goal> {
    const context = { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams) };

    return this.apiService
      .patch$<Goal>(goalId, goal, {
        ...new RequestConfig(),
        ...context,
        queryParams: {
          includeMetrics: gtmhubAdditionalParams.includeMetrics,
        },
      })
      .pipe(
        tap((patchedGoal) => {
          const args = { reason: "goalPatched", detail: { goal: patchedGoal } };
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public approveGoal$(goalId: string, comment?: string): Observable<Goal> {
    return this.post$(
      { comment: comment },
      {
        ...new RequestConfig(),
        url: this.apiService.getApproveEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastService.emit("approvedGoal", goalId);
        this.broadcastWorkflowUpdate("reviewApproved", goalId);
        if (comment) {
          this.broadcastService.emit("commentPostedFromWorkflowUpdate", { targetId: goalId, targetType: "goal" });
        }
      })
    );
  }

  public declineGoal$(goalId: string, comment?: string): Observable<Goal> {
    return this.post$(
      { comment: comment },
      {
        ...new RequestConfig(),
        url: this.apiService.getDeclineEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastWorkflowUpdate("reviewDeclined", goalId);
        this.broadcastService.emit("commentPostedFromWorkflowUpdate", { targetId: goalId, targetType: "goal" });
      })
    );
  }

  public withdrawGoal$(goalId: string): Observable<Goal> {
    return this.post$(
      {},
      {
        ...new RequestConfig(),
        url: this.apiService.getWithdrawEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastWorkflowUpdate("reviewWithdrawn", goalId);
      })
    );
  }

  public sendForApproval$(goalId: string, reviewersIds: []): Observable<Goal> {
    return this.post$(
      {
        reviewers: reviewersIds,
      },
      {
        ...new RequestConfig(),
        url: this.apiService.getSendForApprovalEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastWorkflowUpdate("reviewSentForApproval", goalId);
      })
    );
  }

  public reorderGoalChild$(payload: IGoalChildReorderPayload): Observable<IGoalChildReorderResponse> {
    return this.apiService
      .patch$<IGoalChildReorderResponse>(null, payload, {
        ...new RequestConfig(),
        url: this.apiService.getChildreorderEndpoint(),
      })
      .pipe(
        tap((response) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "goalChildReordered",
            detail: {
              goalId: payload.parentId,
              childId: payload.moved.id,
              childType: payload.moved.type,
              childOrderId: response.orderId,
            },
          });
        })
      );
  }

  public getGoalWorkflowActions$(goalId: string): Observable<IWorkflowActions> {
    return this.get$<IWorkflowActions>(null, {
      ...new RequestConfig(),
      url: this.apiService.getGoalWorkflowActionsEndpoint(goalId),
    });
  }

  private broadcastWorkflowUpdate(update: string, goalId: string): void {
    this.broadcastService.emit(update, goalId);
    this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalChanged" });
    this.broadcastService.emit("goalsChanged");
  }

  public addTag$(goalId: string, title: string, additionalPayloadData?: UpdateGoalAdditionalPayloadData): Observable<Tag> {
    const context = additionalPayloadData ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalPayloadData) } : {};

    return this.apiService
      .post$<Tag>(
        { title },
        {
          ...new RequestConfig(),
          url: this.apiService.getAddGoalTagsEndpoint(goalId),
          ...context,
        }
      )
      .pipe(
        tap((tag: Tag) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagAdded",
            detail: {
              goalId,
              tag,
            },
          });
        })
      );
  }

  public removeTag$(goalId: string, tag: Tag): Observable<void> {
    return this.apiService
      .delete$<void>(null, {
        ...new RequestConfig(),
        url: this.apiService.getRemoveGoalTagsByTitleEndpoint(goalId, [tag.title]),
      })
      .pipe(
        tap(() => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagRemoved",
            detail: {
              goalId,
              tag,
            },
          });
        })
      );
  }

  public achieveGoal$(goalId: string): Observable<void> {
    return this.apiService.put$<void>(
      null,
      { status: "achieved" },
      {
        ...new RequestConfig(),
        url: this.apiService.getStatusEndpoint(goalId),
      }
    );
  }

  public abandonGoal$(goalId: string, reason: string): Observable<void> {
    return this.apiService.put$<void>(
      null,
      { status: "abandoned", reason: reason },
      {
        ...new RequestConfig(),
        url: this.apiService.getStatusEndpoint(goalId),
      }
    );
  }

  public reopenGoal$(goalId: string): Observable<void> {
    return this.apiService.delete$<void>(null, {
      ...new RequestConfig(),
      url: this.apiService.getStatusEndpoint(goalId),
    });
  }

  public getGoalsGroupedByOwners$(sessionId: string, skip = null, take = null, approvalStatus = null): Observable<ICollectionMap<IAsigneeIdsGoalsMap>> {
    return this.apiService.get$<ICollectionMap<IAsigneeIdsGoalsMap>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getGoalsGroupedByOwnersEndpoint(),
      queryParams: {
        sessionId,
        skip,
        take,
        approvalStatus,
      },
    });
  }

  public getGoalsGroupedByReviewers$(sessionId: string, skip = null, take = null, review = null, approvalStatus = null): Observable<ICollectionMap<IAsigneeIdsGoalsMap>> {
    return this.apiService.get$<ICollectionMap<IAsigneeIdsGoalsMap>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getGoalsGroupedByReviewersEndpoint(),
      queryParams: {
        sessionId,
        skip,
        take,
        review,
        approvalStatus,
      },
    });
  }

  public getGoalWithFields$(goalId: string, ctrlFields = fieldsLinks): Observable<Goal> {
    const params = {
      fields: ctrlFields || fieldsLinks,
    };
    return this.apiService
      .get$<Goal>(goalId, {
        queryParams: { ...params },
      })
      .pipe(switchMap((goal) => this.applyColoringToGoal(goal)));
  }

  public createBulkGoals$(okrs: IBulkCreateGoalRequest[]): Observable<IBulkCreateGoalResult[]> {
    return this.apiService.post$<IBulkCreateGoalResult[]>(okrs, {
      url: this.apiService.getCreateBulkGoalsEndpoint(),
    });
  }

  public getAllGoalsV2$<T>(params: GoalsRequestPaging): Observable<ICollection<T>> {
    if (!params.fields) {
      params.fields = getGoalsFieldsV2.split(",");
    }

    return this.getGoalsV2$<T>(params).pipe(
      switchMap((firstBatchCollection) => {
        const { items, totalCount } = firstBatchCollection;
        const take = (params.take || params.limit) as number;

        if (totalCount > take) {
          const stepsPerformed = 1;
          const additionalStepsCount = Math.ceil(totalCount / take) - stepsPerformed;
          const observablesArr = Array.from({ length: additionalStepsCount }, (num, index) => {
            const nextStepOrder = index + 1;
            const skip = nextStepOrder * take;
            return this.getGoalsV2$<T>({ ...params, skip });
          });

          return forkJoin(observablesArr).pipe(
            map((resolvedObservablesArr) => {
              const fullCollection = resolvedObservablesArr.reduce((collection, currentResponse) => {
                if (currentResponse && currentResponse.items && Array.isArray(currentResponse.items)) {
                  collection.push(...currentResponse.items);
                }
                return collection;
              }, items);

              return { items: fullCollection, totalCount };
            })
          );
        }

        return of(firstBatchCollection);
      })
    );
  }
}
