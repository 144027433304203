<div
  class="nav-list-item"
  #itemElement
  [class.nav-list-item--active]="isActive"
  [uiTooltipTitle]="item.isActive === false ? item.title + ' (' + ('deactivated' | localize) + ')' : item.title"
  [uiTooltipTrigger]="isTitleOverflowing ? 'hover' : null"
  [cdkDragDisabled]="config.itemsGroupType !== 'favorites'"
  [class.nav-list-item--hover]="isMenuOpened"
  [attr.aria-label]="item.title"
  (keydown.enter)="handleItemEnterPress(item, $event, itemElement)"
  tabindex="0"
  role="button"
  ui-tooltip
  uiTooltipPlacement="right"
  cdkDragBoundary=".drag-area"
  cdkDrag
  cdkDragRootElement="nav-list-item">
  <i class="nav-list-item__drag-handle" *ngIf="config.itemsGroupType === 'favorites'" [class.nav-list-item__drag-handle--show]="isMenuOpened" uiSize="sm" cdkDragHandle ui-icon uiType="drag-icon"></i>
  <nav-list-item-icon
    class="nav-list-item__icon"
    [class.nav-list-item__icon--hide]="isMenuOpened"
    [itemsType]="config.itemsType"
    [class.nav-list-item__icon--show]="config.itemsGroupType !== 'favorites'"
    [item]="item"></nav-list-item-icon>
  <div class="nav-list-item__title" [attr.id]="item.id" [attr.data-test-id]="'item-sub-nav-section-' + config.itemsGroupType" (click)="view(item)">
    {{ item.title }}
    <span class="deactivated-label" *ngIf="item.isActive === false">({{ "deactivated" | localize }})</span>
  </div>
  <nav-list-item-context-menu
    class="nav-list-item__context-menu"
    [class.nav-list-item__context-menu--show]="isMenuOpened"
    [isNextNavActive]="true"
    [isMenuOpened]="isMenuOpened"
    [item]="item"
    [canMoveUp]="canMoveUp"
    [canMoveDown]="canMoveDown"
    [config]="{ itemsType: config.itemsType, itemsGroupType: config.itemsGroupType, broadcastConfig: config.broadcastConfig.navItemContextMenuActions }"
    (menuToggled)="onMenuToggled($event)"
    (markItemAsFavorite)="markItemAsFavorite.emit($event)"
    (unmarkItemAsFavorite)="unmarkItemAsFavorite.emit($event)"
    (removeFromSuggested)="removeFromSuggested.emit($event)"
    (moveUp)="moveUp.emit($event)"
    (moveDown)="moveDown.emit($event)">
  </nav-list-item-context-menu>
  <div class="grey-background"></div>
</div>
