<ui-loading-indicator [uiSpinning]="loading" [uiTip]="'loading' | localize" [uiIndicator]="loadingTemplate">
  <div *ngIf="sections.length" [ngClass]="['assignee-active-okrs-main-wrap', 'view-mode-' + viewMode, 'grouped-by-' + groupBy]">
    <ui-collapse>
      <ui-collapse-panel
        *ngFor="let group of sections"
        [collapsePanelActive]="sectionsState[group.id]?.panel"
        [class.summary-expanded]="sectionsState[group.id]?.summary"
        [collapsePanelHeader]="panelHeaderTemplate"
        [collapsePanelExpandedIcon]="expandedIcon"
        [uiOnlyContentBorder]="true"
        [uiSize]="'small'"
        [stateSessionId]="group.id"
        [stateAssigneeId]="assigneeId"
        [defaultState]="sectionsState[group.id]"
        (stateChange)="stateChanged(group.id, $event)"
        assignee-active-okrs-list-state>
        <ng-template #expandedIcon>
          <i class="collapse-arrow" [uiRotate]="sectionsState[group.id]?.panel ? 90 : 0" ui-icon uiType="chevron-right-small"></i>
        </ng-template>
        <ng-template #panelHeaderTemplate>
          <div class="panel-header" [class.panel-header-active]="sectionsState[group.id]?.panel" [ghTrackMeta]="getTrackingMetadata(group.id)" ghTrack="Button Clicked">
            <h4 [attr.aria-label]="getSectionHeaderAriaLabel(group)" [uiTooltipTitle]="group.isRestricted ? restrictedSessionTitle : group.title" ui-tooltip tooltipIfOverflow="hover">
              {{ group.isRestricted ? restrictedSessionTitle : group.title }}
              <span *ngIf="group?.isActive === false" class="deactivated-label">({{ "deactivated" | localize }})</span>
            </h4>
            <ui-badge
              class="sp-mr-8"
              [a11yLabel]="group.items.length + ' OKRs.'"
              [uiCount]="group.items.length"
              [uiStyle]="{ backgroundColor: 'transparent', color: '#0D232F', fontWeight: '700', border: '1px solid #7485A2', fontSize: '10px', display: 'flex', alignItems: 'center' }"
              uiSize="small"
              uiStandalone />
            <div
              class="columns"
              [ngClass]="{
                'session-grouping': groupBy === 'session',
                'ownership-grouping': groupBy === 'ownership',
              }">
              <p class="column session-col" *ngIf="groupBy === 'ownership'">{{ "session" | localize }}</p>
              <p class="column confidence-col">{{ "confidence" | localize }}</p>
              <p class="column owner-col">{{ "owner_cap" | localize }}</p>
              <p class="column progress-col">{{ "progress_cap" | localize }}</p>
            </div>
          </div>
        </ng-template>
        <div class="panel-content">
          <div class="panel-content-okrs-grid" [class.reserved-session-report-space]="groupBy === 'session'">
            <div class="session-okrs-wrap" *ngFor="let objective of group.items">
              <div class="okr-item-row goal-row">
                <h6 class="item-name-container">
                  <item-name-with-icon
                    class="title-nowrap"
                    [a11yLabelExtra]="getItemWithIconExtraLabel(objective)"
                    [name]="getItemName(objective)"
                    [obfuscated]="objective.obfuscated && !objective.isRestricted"
                    [isDisabled]="objective.isRestricted || objective.obfuscated"
                    [showTooltipWhenTextHidden]="true"
                    (nameClick)="openGoal(objective.id)"
                    icon="goal"></item-name-with-icon>
                </h6>

                <span class="session-name-container" *ngIf="groupBy === 'ownership'" [uiTooltipTitle]="objective.sessionName" (nameClick)="openGoal(objective.id)" ui-tooltip tooltipIfOverflow="hover">
                  {{ objective.sessionName }}
                </span>

                <!-- deliborately left empty element - OKRs don't specify confidence (just KRs do), but this element keeps the structure table-like -->
                <span class="confidence-label-container"></span>

                <ng-container *ngIf="!objective.isRestricted">
                  <ui-assignee-avatar-group [uiMax]="2" uiSize="xs">
                    <ui-assignee-avatar *ngFor="let assignee of objective.ownerIds" [fromAssigneeId]="assignee" />
                  </ui-assignee-avatar-group>

                  <metric-progress-bar
                    [uiHideBar]="progressBarState[group.id]?.hideBar"
                    [uiHideDelta]="progressBarState[group.id]?.hideDelta"
                    [progress]="objective.attainment"
                    [delta]="objective.attainmentDelta"></metric-progress-bar>
                </ng-container>
              </div>

              <div class="okr-item-row metric-row" *ngFor="let metric of objective.metrics">
                <h6 class="item-name-container">
                  <item-name-with-icon
                    class="title-nowrap"
                    [a11yLabelExtra]="getMetricNameLabel(metric)"
                    [name]="objective.isRestricted && isMetricRestricted(metric) ? restrictedMetricTitle : metric.name"
                    [showTooltipWhenTextHidden]="true"
                    [isDisabled]="objective.isRestricted && isMetricRestricted(metric)"
                    (nameClick)="openMetric(metric.id)"
                    icon="metric"></item-name-with-icon>
                </h6>

                <!-- deliborately left empty element - KRs don't specify parent session (just OKRs do), but this element keeps the structure table-like -->
                <span class="session-name-container" *ngIf="groupBy === 'ownership'"></span>
                <ng-container *ngIf="objective.isRestricted === undefined || (objective.isRestricted && !isMetricRestricted(metric))">
                  <span class="confidence-label-container">
                    <confidence-label [isPreview]="true" *ngIf="metric.confidence" [confidenceValue]="metric.confidence.value" />
                  </span>

                  <ui-assignee-avatar-group [uiMax]="2" uiSize="xs">
                    <ui-assignee-avatar *ngFor="let assignee of metric.ownerIds" [fromAssigneeId]="assignee" />
                  </ui-assignee-avatar-group>

                  <metric-progress-bar
                    [uiHideBar]="progressBarState[group.id]?.hideBar"
                    [uiHideDelta]="progressBarState[group.id]?.hideDelta"
                    [progress]="metric.attainment"
                    [delta]="metric.attainmentDelta"></metric-progress-bar>
                </ng-container>
              </div>
            </div>
          </div>

          <session-summary-report
            *ngIf="groupBy === 'session'"
            [isDisabled]="group.isRestricted"
            [sessionId]="group.id"
            [expanded]="sectionsState[group.id]?.panel && sectionsState[group.id]?.summary"
            [trackMeta]="getSummaryReportExtraTrackingMetadata(group.id)"
            [contextTeamId]="assigneeType === 'team' ? assigneeId : ''"></session-summary-report>
        </div>
      </ui-collapse-panel>
    </ui-collapse>
  </div>
</ui-loading-indicator>

<ng-template #loadingTemplate>
  <widget-skeleton />
</ng-template>

<ng-container *ngIf="!loading && !sections.length">
  <container-empty-state *hasAllPermissions="'ManageGoals'; negate: true" [message]="'you_will_find_team_okrs_here' | localize" pictureUrl="/dist/img/home/widgets/empty_myokrs.svg" />

  <container-empty-state *hasAllPermissions="'ManageGoals'" [message]="'draft_okrs_on_your_own_or_get_help' | localize" pictureUrl="/dist/img/home/widgets/empty_myokrs.svg">
    <create-okr-split-button *ngIf="this.assigneeType === 'user'" />
  </container-empty-state>
</ng-container>
