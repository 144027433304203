import { IHttpService, IPromise, IRequestShortcutConfig, IRootScopeService } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { ApmSpanService } from "@gtmhub/core/tracing/apm-span.service";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { HubWorkflowActions, HubWorkflowItemsCollection } from "@webapp/filters/models/hub-filter.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import {
  IAccess,
  IApprovalWorkflowValidation,
  IPlanningSessionConflict,
  IPlanningSessionParams,
  IPlanningSessionStats,
  IPlanningSessionStatsAssignee,
  IPlanningSessionWorkflow,
  ISessionWorkflowStats,
  Session,
} from "@webapp/sessions/models/sessions.model";
import { IGoal } from "../../goals/models";
import { PlanningSessionEvents } from "./events";

export class PlanningSessionService {
  public static $inject = ["$http", "EnvironmentService", "$rootScope", "ApmSpanService", "GoalsFacade"];
  private events: PlanningSessionEvents;

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private $rootScope: IRootScopeService,
    private apmSpanService: ApmSpanService,
    private goalsFacade: GoalsFacade
  ) {
    this.events = new PlanningSessionEvents(this.$rootScope);
  }

  public getGoalsHit(id: string, tag: string): IPromise<IGoal[]> {
    const url = this.env.getApiEndpoint("/sessions/" + id + "/stats/goalsMet");
    const query: IRequestShortcutConfig = {
      params: {
        tags: tag,
      },
    };

    return this.$http.get<ICollection<IGoal>>(url, query).then((response) => this.goalsFacade.applyColoringToGoalCollection(response.data).items);
  }

  public getBadMetrics(id: string, tag: string): IPromise<Metric[]> {
    const url = this.env.getApiEndpoint("/sessions/" + id + "/stats/badMetrics");
    const query: IRequestShortcutConfig = {
      params: {
        tags: tag,
      },
    };

    return this.$http.get<ICollection<Metric>>(url, query).then((response) => this.goalsFacade.applyColoringToMetricCollection(response.data).items);
  }

  public getGoodMetrics(id: string, tag: string): IPromise<Metric[]> {
    const url = this.env.getApiEndpoint("/sessions/" + id + "/stats/goodMetrics");
    const query: IRequestShortcutConfig = {
      params: {
        tags: tag,
      },
    };

    return this.$http.get<ICollection<Metric>>(url, query).then((response) => this.goalsFacade.applyColoringToMetricCollection(response.data).items);
  }

  public getMetricsHit(id: string, tag: string): IPromise<Metric[]> {
    const url = this.env.getApiEndpoint("/sessions/" + id + "/stats/metricsMet");
    const query: IRequestShortcutConfig = {
      params: {
        tags: tag,
      },
    };

    return this.$http.get<ICollection<Metric>>(url, query).then((response) => this.goalsFacade.applyColoringToMetricCollection(response.data).items);
  }

  public getManualMetrics(id: string, tag: string): IPromise<Metric[]> {
    const url = this.env.getApiEndpoint("/sessions/" + id + "/stats/manualMetrics");
    const query: IRequestShortcutConfig = {
      params: {
        tags: tag,
      },
    };

    return this.$http.get<ICollection<Metric>>(url, query).then((response) => this.goalsFacade.applyColoringToMetricCollection(response.data).items);
  }

  public getDynamicMetrics(id: string, tag: string): IPromise<Metric[]> {
    const url = this.env.getApiEndpoint("/sessions/" + id + "/stats/dynamicMetrics");
    const query: IRequestShortcutConfig = {
      params: {
        tags: tag,
      },
    };

    return this.$http.get<ICollection<Metric>>(url, query).then((response) => this.goalsFacade.applyColoringToMetricCollection(response.data).items);
  }

  /**
   * Returns the sessions in which a user or a team and its members have okrs or/and krs
   */
  public getRelevantSessions(target: { type: "user" | "team"; id: string }, params = {} as IPlanningSessionParams): IPromise<Session[]> {
    const query: IRequestShortcutConfig = {
      params,
    };

    const url = this.env.getApiEndpointV2(`/sessions/${target.type}/${target.id}/relevant`);

    return this.$http.get<ICollection<Session>>(url, query).then((response) => {
      return response.data.items;
    });
  }

  public getPlanningSessions(params?: IPlanningSessionParams): IPromise<Session[]> {
    if (!params) {
      params = {};
    }

    if (!params.fields) {
      params.fields =
        "isSelfApproveAllowed,accountId,end,start,title,status,access,cadence,cadenceType,parentId,color,workflow,tags,cadenceTimeZone,cadenceStart,settings";
    }

    const query: IRequestShortcutConfig = {
      params,
    };

    const url = this.env.getApiEndpoint("/sessions");

    return this.apmSpanService.dataLoadSpan("planning-sessions-load", () => {
      return this.$http.get<ICollection<Session>>(url, query).then((response) => {
        return response.data.items;
      });
    });
  }

  public getSessionsV2(params: IRestLayerRequest): IPromise<ICollection<Partial<Session>>> {
    if (!params.fields) {
      params.fields = "title";
    }

    const url = this.env.getApiEndpointV2("/sessions");

    const config: IRequestShortcutConfig = {
      params,
    };

    return this.$http.get<ICollection<Partial<Session>>>(url, config).then((response) => response.data);
  }

  public createPlanningSession(session: Session): IPromise<Session> {
    const url = this.env.getApiEndpoint("/sessions");

    const gtmhubAdditionalParams: IRequestShortcutConfig = {
      params: {
        gtmhubAdditionalParams: {
          teamsWithSubteamsPermissionsCount: this.getTeamsWithSubteamsPermissionsCount(session),
        },
      },
    };

    return this.$http.post<Session>(url, session, gtmhubAdditionalParams).then((response) => response.data);
  }

  public checkForConflictsInSession(planningSession: Session, action: string): IPromise<ICollection<IPlanningSessionConflict>> {
    const url = this.env.getApiEndpoint("/sessions/" + planningSession.id + "/conflicts");

    let inherits = false;
    for (let i = 0; i < planningSession.access.permissions.length; i++) {
      if (planningSession.access.permissions[i].principalKind === "account") {
        inherits = true;
      }
    }

    planningSession.access.inherits = inherits;

    const requestPayload: { session: Session; action: string } = {
      session: planningSession,
      action: action,
    };

    return this.$http.post<ICollection<IPlanningSessionConflict>>(url, requestPayload).then((response) => response.data);
  }

  public updatePlanningSession(planningSession: Session): IPromise<Session> {
    const url = this.env.getApiEndpoint("/sessions/" + planningSession.id);

    const gtmhubAdditionalParams: IRequestShortcutConfig = {
      params: {
        gtmhubAdditionalParams: {
          teamsWithSubteamsPermissionsCount: this.getTeamsWithSubteamsPermissionsCount(planningSession),
        },
      },
    };

    return this.$http.put<Session>(url, planningSession, gtmhubAdditionalParams).then((response) => {
      this.events.broadcastSessionUpdated(planningSession);
      return response.data;
    });
  }

  public stats(id: string, args?: { tags?: string; assigneeId?: string }): IPromise<IPlanningSessionStats> {
    const url = this.env.getApiEndpoint(`/sessions/${id}/stats`);

    const query: IRequestShortcutConfig = {
      params: args,
    };

    return this.$http.get<IPlanningSessionStats>(url, query).then((response) => response.data);
  }

  public statsAssignees(id: string, args?: { assigneeIds: string }): IPromise<IPlanningSessionStatsAssignee[]> {
    const url = this.env.getApiEndpoint(`/sessions/${id}/stats/assignees`);

    const query: IRequestShortcutConfig = {
      params: args,
    };

    return this.$http.get<IPlanningSessionStatsAssignee[]>(url, query).then((response) => response.data);
  }

  public getWorkflowStats(sessionId: string): IPromise<ISessionWorkflowStats> {
    const url: string = this.env.getApiEndpoint(`/sessions/${sessionId}/stats/workflow-stats`);

    return this.$http.get<ISessionWorkflowStats>(url).then((response) => response.data);
  }

  public nudgePeople(sessionId: string, audience: string, message: string): IPromise<unknown> {
    const url: string = this.env.getApiEndpoint(`/sessions/${sessionId}/nudge`);
    const payload: { audience: string; message: string } = {
      audience: audience,
      message: message,
    };

    return this.$http.post<unknown>(url, payload).then((response) => response.data);
  }

  public getCurrentUserActionsCount(): IPromise<HubWorkflowActions> {
    const url: string = this.env.getApiEndpoint("/sessions/hub/workflow");

    return this.$http.get<HubWorkflowActions>(url).then((response) => response.data);
  }

  public getCurrentUserWorkflowItems(fields?: string, workflowStatus?: string): IPromise<HubWorkflowItemsCollection> {
    const url: string = this.env.getApiEndpoint("/sessions/hub/workflow-items");

    const query: IRequestShortcutConfig = {
      params: {},
    };

    if (fields && fields.trim().length > 0) {
      query.params.fields = fields;
    }

    if (workflowStatus) {
      query.params.workflowStatus = workflowStatus;
    }

    return this.$http.get<HubWorkflowItemsCollection>(url, query).then((response) => response.data);
  }

  public validateSessionApprovalWorkflowSettings(
    workflow: IPlanningSessionWorkflow,
    sessionAccess: IAccess,
    { ownerCanApprove }: { ownerCanApprove: boolean }
  ): IPromise<IApprovalWorkflowValidation> {
    const url = this.env.getApiEndpoint("/sessions/validate-approval-settings");

    const payload = {
      userIds: workflow.approvalSettings.usersAllowedToApprove,
      teamIds: workflow.approvalSettings.teamsAllowedToApprove,
      numberOfReviewersRequired: workflow.reviewers,
      everyoneCanApprove: workflow.approvalSettings.everyoneCanApprove,
      ownerCanApprove,
      access: sessionAccess,
    };

    return this.$http.post<IApprovalWorkflowValidation>(url, payload).then((response) => response.data);
  }

  private getTeamsWithSubteamsPermissionsCount(session: Session): number {
    if (!session.access || !session.access.permissions) {
      return 0;
    }
    let count = 0;
    session.access.permissions.forEach((permission) => {
      if (permission.principalKind === "team_and_subteams") {
        count++;
      }
    });

    return count;
  }
}
