export interface UserSignatureResponse {
  secureId: string;
}

export interface FeatureTogglesModel {
  secureId: string;
}

export interface FeatureTogglesDtoModel {
  secureId: string;
}

export enum FeatureFlag {
  ModuleScimAccountSettings = "enable-scim-account-settings",
  RestConnector = "restconnector",
  RestConnectorOAuth = "restconnectoroauth",
  FeatureWhiteboardBasicShapes = "whiteboard-basic-shapes",
  MarketplaceDirectSubmissionOfInsights = "marketplace_direct_submission_of_insights",
  LockMultiAccount = "lock-multi-account",
  EnableTagManagement = "enable-tag-management",
  EnableInsightRenderingOptimisations = "enable-insight-rendering-optimisations",
  ChartsInLists = "charts-in-lists",
  ShowTheUserWhoCreated = "show-the-user-who-created",
  HideExternalTeams = "hide-external-teams",
  FeatureSSOSettings = "sso-settings",
  WhiteboardsSessionManagement = "whiteboards-session-management",
  WorkdayProvisioning = "workday-provisioning",
  FeatureReflections = "reflections",
  ActivityHistory = "audit-trail",
  HideKPIsPermissions = "hide-kpis-permissions",
  DisableDelightedSurveys = "disable-delighted-surveys",
  MandatoryOkrAlignmentFeature = "mandatory-alignment-of-okrs",
  SCIMGroups = "scim-groups",
  DisableTasksFromDesignScore = "disable-tasks-from-design-score",
  QuantivePlusSuggestions = "kr-tenx-suggestions",
  SlackChannelDropdownDisabled = "slack-channel-dropdown-disabled",
  ShareableLinksWithAppliedFilters = "shareable-links-with-applied-filters",
  UploadMediaAvatar = "upload-medi-avatar",
  PiSuggestionDrawerOverviewTab = "pi-suggestion-drawer-overview-tab",
  PiSuggestionDrawerAllSuggestionsTab = "pi-suggestion-drawer-all-suggestions-tab",
  PiSuggestionDrawerWhenCreateObjective = "pi-suggestion-drawer-when-create-objective",
  PiSettings = "pi-settings",
  BypassManageDataPermission = "bypass-manage-data-permission",
  PrivacyNotice = "privacy-notice",
  Arabic = "arabic",
  QuantiveStaticCdn = "quantivestatic-cdn",
  OkrViews = "okr-views",
  AccountMarketingInfo = "account-marketing-info",
  PiWhiteboardSuggestions = "pi-whiteboard-suggestions",
  AchievedOrNotMetricType = "achieved-or-not-metric-type",
  SessionFilterResetToCurrent = "session-filter-reset-to-current",
  PiInsightsTextToSql = "pi-insights-text-to-sql",
  OkrsPrivateGoals = "okrs.private-goals",
  MyOkrsGroupSortDropdown = "my-okrs-group-sort-dropdown",
  IncludeConnectionID = "Include-connection-id",
  LinkingKPIsToOKRsInRelatedItems = "linking-kpis-to-okrs-related-items",
  UsersGranularAccess = "users-granular-access",
  OkrGrid = "okr-grid",
  AutomatingKRWithAKPI = "linking-okrs-to-kpis-automating-a-kr-with-a-kpi",
  StrategyAIEnabled = "strategy-ai-enabled",
  SingularityAccess = "singularity-access",
  SingularityEffortAlignmentObjectiveReports = "singularity-effort-alignment-objective-reports",
  QuantivePlusSuggestionsKrV2 = "quantive-plus-suggestions-kr-v2",
  AutomationSelectorDetailed = "automation-selector-detailed",
  StrategicGuidedOkrKrV2 = "strategic-guided-okr-kr-v2",
  StrategicGuidedOkrObjectiveV2 = "strategic-guided-okr-objective-v2",
  ResultsPIGlobal = "results-pi-global",
  WhiteboardObjectiveKRSuggestions = "whiteboard-objective-kr-suggestions-v2",
  PlatformLD = "platform-ld",
  PlatformRedirect = "platform-redirect",
  FeedFeatureToggle = "feed-feature-toggle",
  AiAssistant = "ai-assistant",
}
